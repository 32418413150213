<template>
<section v-if="faqs" class="packagebox faqs" id="scrFaqs">
    <div class="packagebox__headline">
        <h2 class="packagebox__headline-title">FAQs</h2>
    </div>
    <div class="faqs__item" v-for="(faq,index) in faqs" :key="index" v-bind:class="{factive:index==0}">
        <h3 class="faqs__item-que">{{faq.question}}</h3>
        <div class="faqs__item-detail" v-if="index==0">
            <div class="reviser" v-html="faq.answer" >
            </div>
        </div>
        <div class="faqs__item-detail" v-else style="display:none">
            <div class="reviser" v-html="faq.answer" >
            </div>
        </div>
    </div>
</section>
</template>
<script>
import axios from 'axios';
import jQuery from 'jquery';
import {defineAsyncComponent} from "@vue/runtime-core";
export default {
    name:'FAQ',
    data(){
        return{
            faqs: undefined,
            slug:this.$route.params.slug,
            apiPath: process.env.VUE_APP_API,
        }
    },
    async mounted(){
        this.getfaqs(this.slug);
        
    },
    async updated(){
        jQuery('.faqs__item-que').on('click',function() {
            jQuery(this).parents('.faqs__item').toggleClass('factive');
		jQuery(this).siblings('.faqs__item-detail').slideToggle();
        });
    },
    methods:{
        getfaqs(slugurl) {
            axios.get(this.apiPath + 'faqs/' + slugurl)
                .then(faqresponse => {
                    this.faqs = faqresponse.data;
                })
        }
        
    },
    watch:{
        slug(){
            this.newSlug = this.$route.params.slug;
            this.getfaqs(this.newSlug);
        }
    }
}
</script>